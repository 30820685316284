import React, { useContext } from 'react'
import Breadcrumbs from '../../components/complex/Breadcrumbs'
import { Helmet } from 'react-helmet'
import BasketContainer from '../../components/complex/BasketContainer'
import useUid from '../../hooks/useUid.hook'
import useFetchItems from '../../hooks/useFetchItems'
import CatalogServices from '../../services/general/CatalogServices'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'

const Cart = observer(() => {
    const {uid, setUid} = useUid();

    const {app} = useContext(Context);

    const {
        items,
        fetchItems,
        loading
    } = useFetchItems(CatalogServices.cart.index, uid)

  return (
    <>{loading ?
        <div className="preloader">
            <div className="isLoading"></div>
        </div>
        :
        <div>
            <Helmet>
                <title>{app.localizationsItems?.cart}</title>
            </Helmet>
            <Breadcrumbs title={app.localizationsItems?.cart}/>
            <BasketContainer items={items} uid={uid} fetchItems={fetchItems}/>
        </div>}
    </>
  )
})

export default Cart