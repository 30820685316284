import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import Advantages from "../../components/complex/Advantages";
import MainOffer from "../../components/complex/MainOffer";
import NewsGrid from "../../components/complex/NewsGrid";
import NewsGrid2 from "../../components/complex/NewsGrid2";
import Partners from "../../components/complex/Partners";
import PointsBlock from "../../components/complex/PointsBlock";
import useFetchItems from "../../hooks/useFetchItems";
import AppServices from "../../services/general/AppServices";
import Description from "../../components/simple/Description";
import { useNavigate, useParams } from "react-router-dom";
import MainProducts from "../../components/complex/MainProducts";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import AdvertisingBanner from "../../components/complex/AdvertisingBanner";
import PopupModal from "../../components/modals/PopupModal";

const MainPage = observer(() => {
    const {app} = useContext(Context);

    const navigate = useNavigate();
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}`);
            }
        }
    }, [app.lang])

    const { 
        items, 
        loading,
    } = useFetchItems(AppServices.page, "main");
  
  return (
    <>
      {items ?
          <>
            <Helmet>
              <title>{items.title}</title>
            </Helmet>
            {items.elements?.map((elem, index) => 
                <div key={index}>
                    {elem.type === "opening" ?
                            <MainOffer item={elem} />
                        :
                        elem.type === "advantages" ?
                            <Advantages item={elem}/>
                        :
                        elem.type === "projects" ?
                            <NewsGrid item={elem}/>
                        :
                        elem.type === "partners" ?
                            <Partners item={elem}/>
                        :
                        elem.type === "articles" ?
                            <NewsGrid2 item={elem}/>
                        :
                        elem.type === "steps" ?
                            <PointsBlock item={elem}/>
                        :
                        elem.type === "text-information" ?
                            <Description item = {elem}/>
                        :
                        elem.type === "products" ?
                            <MainProducts item={elem}/>
                        :
                        elem.type === "advertising-banner-2" ?
                            <AdvertisingBanner item={elem}/>
                        :
                            <></>
                    }
                </div>
            )}
            <PopupModal/>
          </>
        :
        <div className="preloader">
            <div className="isLoading"></div>
        </div>
        }
    </>
  );
})

export default MainPage;