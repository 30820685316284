import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Context } from '../..';

const LanguagesDropdown = observer(() => {
    const {app} = useContext(Context);
    
  return (
    <div className="module-setting">
        <div className="mysetting language-switcher">
            <div className="tongle">
                <span>{app.lang}</span>
            </div>
            <div className="content">
                <div className="language-switcher">
                    <ul id="select-language">
                        {app.languages.map((lang, index) =>
                            <li key={index} onClick={() => app.setLang(lang.slug)}>
                                <div>
                                    <span>{lang.title}</span>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
})

export default LanguagesDropdown