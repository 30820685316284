import i18next from "i18next";

export default class Utils {
    static removeLastDirectoryPartOf(url) {
        let arr = url.split('/');
        arr.pop();
        return( arr.join('/') );
    }

    static getCookie(name) {
        var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }
    
    static setCookie(name, value, options = {}){
        options = {
            path: '/',
            ...options
          };
        
          if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
          }
        
          let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
        
          for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
              updatedCookie += "=" + optionValue;
            }
          }
        
          document.cookie = updatedCookie;
    }

    static getScrollBarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    static numberWithSpaces(x) {
        if(x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    static objectToSearchParams = (obj) => {
        return Object.entries(obj).map(([key, val]) => {
            if (val) {
                if (typeof val === 'object') {
                    return val.length ? val.map(val2 => `${key}[]=${val2}`).join('&') : null
                }
                else {
                    return `${key}=${val}`
                }
            } else {
                return null
            }
        }).filter(item => item)
            .join('&');
    }

    static transformCoordinates = (coordinates) => {
        return {
            latitude: coordinates && coordinates[0],
            longitude: coordinates && coordinates[1]
        }
    }

    static trimText = (text, length, end = '') => {
        if(text.length >= 20){
            let index = text.indexOf(" ", length);

            if( index == -1 ) index = length;
            
            return text.slice(0, index) + end;
        }else{
            return text
        }
    }

    static transformFiles = (files) => {
        return files.filter(item => !['error', 'uploading', 'removed'].includes[item.status]).map(item => {
            return {
                url: item.url ? item.url : item.response.content.value,
                type: item.type,
                name: item.name
            }
        })
    }

    static transformFilesFromServer = files => {
        return files.map((item, index) => {
            return {
                uid: -index,
                url: item.url,
                status: "done",
                type: item.type || 'image/jpg',
                name: item.name
            }
        })
    }

    static isEmail = email => {
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return !(!email || regex.test(email) === false);
    }

    static generateDate = (data) => {
        const month_ru = ["Январь", "Февраль", "Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","ноябрь","Декабрь"]
        const month_kk = ["Қаңтар", "Ақпан", "Наурыз","Сәуір","Мамыр","Маусым","Шілде","Тамыз","Қыркүйек","Қазан","Қараша","Желтоксан"]

        const date = new Date(data);
        const count = date.getMonth()
        let month = month_ru[count];
        if(i18next.language === 'ru'){
            month = month_ru[count];
        }else if(i18next.language === 'kk'){
            month = month_kk[count];
        }
        let dd = date.getUTCDate() + ' ' + month + ' ' + date.getUTCFullYear()
        return dd
    }

    static youtube_parser = (image) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = image.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
      }
  
    static checkurl = (url) => {
        return url.replace("http://","").replace("https://","").replace("www.","").replace("youtu.be/","youtube.com?v=").slice(0,14)==="youtube.com?v=" || 
        url.replace("http://","").replace("https://","").replace("www.","").replace("youtu.be/","youtube.com?v=").slice(0,14)==="youtube.com/wa";
    }
}
