import React from "react";
import img from "../../images/placeholder.png"

const NewsCard = ({item, ...props}) => {
  return (
    <div className="item" {...props}>
        <div className="banner5">
            <img src={item.image ? item.image : img} alt="" 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
        </div>
        <div className="blog-page">
            <div className="blog-right">
                <div className="style1">
                    <p>{item.location}</p>
                </div>
                <p className="style2">{item.title}</p>
                <p className="style3">
                    {item.description}
                </p>
            </div>
        </div>
    </div>
  );
};

export default NewsCard;
