import React from "react";
import OwlCarousel from 'react-owl-carousel';
import useFetchItems from "../../hooks/useFetchItems";
import PartnersServices from "../../services/general/PartnersServices";
import img from "../../images/placeholder.png"

const Partners = ({item, title}) => {
    const { 
        items,
    } = useFetchItems(PartnersServices.index);

    const options = {
        nav: true,
        dots: false,
        loop: true,
        responsive:{
            0:{
                items:2
            },
            480:{
                items:3
            },
            600:{
                items:4
            },
            980:{
                items:5
            },
            1000:{
                items:6
            }
        }
    }

  return (
    items?.items?.length &&
    <div id="sns_partners" className="wrap">
        <div className="container">
            <div className="block-title">
                <h3>{title ? title : item?.title}</h3>
            </div>
            <div className="slider-wrap">
                <div className="partners_slider_in">
                        <OwlCarousel
                            id="partners_slider1"
                            className="our_partners owl-carousel owl-theme owl-loaded"
                            style={{display: "inline-block"}}
                            {...options}
                        >
                            {items.items.map((item, index) => 
                                <div className="item" key={index}>
                                    <a className="banner11" href={item.link} target="_blank">
                                        <img alt={item.title} src={item.image ? item.image : img} 
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
                                    </a>
                                </div>
                            )}
                        </OwlCarousel>
                    
                </div>
            </div>
        </div>
    </div>
  );
};

export default Partners;
