import React, { useEffect, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import { ERROR_PAGE_ROUTE } from './Constants';
import { publicRoutes } from './Routes';

const AppRouter = () => {
  return (
    <Routes>
        {publicRoutes.map(({path, Component}) => 
            <Route key={path} path={path} element={
                <Component></Component>
            } exact/>
        )}
        <Route
            path="*"
            exact
            element={<Navigate to={ERROR_PAGE_ROUTE}/>}
        />
    </Routes>
  )
}

export default AppRouter