import React from 'react'
import img1 from "../../images/banner.jpg"

const SubCategoryBanner = ({items}) => {
  return (
    <div className='container sub-banner'>
        <h1>{items.title}</h1>
        <div className='page-top'>
            <div>
                <img src={items.image ? items.image : img1} alt={items.title} title={items.title}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src=img1
                    }}
                />
            </div>
            <div dangerouslySetInnerHTML={{ __html: items.description }}></div>
        </div>
    </div>
  )
}

export default SubCategoryBanner