import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router-dom'
import Article from '../../components/complex/Article'
import Breadcrumbs from '../../components/complex/Breadcrumbs'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/AppServices'
import { Context } from '../..'
import { observer } from 'mobx-react-lite'

const SamplePage = observer(() => {
    const {id} = useParams();
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const{lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2){
                navigate(`/${app.lang}/page/${id}`)
            }
        }
    }, [app.lang])

    const { 
        items, 
    } = useFetchItems(AppServices.page, id);

  return (
    <>
        <Helmet>
            <title>{items?.title}</title>
        </Helmet>
        <Breadcrumbs title={items?.title}/>
        {items ? 
            <div className="blog-pagev1 detail cms-simen-home-page-v2 default cmspage">
                <Article item={items}/>
            </div>
            :
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
        }
    </>
  )
})

export default SamplePage