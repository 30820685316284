import React, { useContext, useEffect, useState } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/CatalogServices';
import Pagination from './Pagination';
import CategoriesProductCard from '../simple/CategoriesProductCard';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchProducts = observer(() => {
    const [term, setTerm] = useState('');
    let query = new URLSearchParams(useLocation().search);
    const {app} = useContext(Context);
    const navigate = useNavigate()

    useEffect(() => {
        if(query.get('term')){
            setTerm(query.get('term'))
        }
    }, [])

    const { 
        items, 
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(CatalogServices.products.index);

    const searchClick = () => {
        setFilterData(`?term=${term}`)
        navigate({
            search: query.toString()
        });
    }

  return (
    <> 
            <div className={loading ? 'container isLoading' : 'container'}>
                <div className='search-container'>
                    <input type="text" className="input-text" placeholder="Поиск...."
                        value={term}
                        onChange={e => setTerm(e.target.value)}
                    />
                    <button onClick={searchClick}>{app.localizationsItems?.search}</button>
                </div>
                {items?.items?.length >= 1 &&
                    <div className='categories-product-list'>
                        <div className='categories-product-title'>
                            <div>{app.localizationsItems?.product_name}</div>
                            <div>{app.localizationsItems?.product_price}</div>
                            <div>{app.localizationsItems?.product_buy}</div>
                        </div>
                        {items?.items?.map((card, index) =>
                            <CategoriesProductCard card={card} key={index}/>
                        )}
                    </div>
                }
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
        
    </>
  )
})

export default SearchProducts