import React from 'react'
import CatalogServices from '../../services/general/CatalogServices'
import useFetchItems from '../../hooks/useFetchItems'
import CategoryCard from '../simple/CategoryCard'

const CatalogCategories = () => {
    const {
        items,
        loading,
    } = useFetchItems(CatalogServices.categories.index)

  return (
    <>
    {loading ?
        <div className="isLoading"></div>
        :
        <div className='container'>
            <div className="catalog-grid">
                {items?.items?.map((card, index) =>
                    <CategoryCard card={card} key={index}/>
                )}
            </div>
        </div>
    }
    </>
  )
}

export default CatalogCategories