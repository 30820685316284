import React from 'react'
import { Nav, Tab } from 'react-bootstrap'

const TabProductPane = ({item}) => {

  return (
    <Tab.Container defaultActiveKey="tab-description0">
        <div className="product-tabs__list">
            <div className="product-tabs__list-body">
                <Nav className="nav-tabs">
                    {item?.map((card, index) =>
                        <Nav.Item key={index}>
                            <Nav.Link eventKey={`tab-description${index}`}>{card.title}</Nav.Link>
                        </Nav.Item>
                    )}
                </Nav>
            </div>
        </div>
        <Tab.Content className="tab-content">
            {item?.map((card, index) =>
                <Tab.Pane className="tab-pane" eventKey={`tab-description${index}`} key={index}>
                    <div className="typography" dangerouslySetInnerHTML={{ __html: card.description }}/>
                </Tab.Pane>
            )}
        </Tab.Content>
    </Tab.Container>
  )
}

export default TabProductPane