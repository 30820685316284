import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Checkbox from "../simple/Checkbox";

const Filter = ({categories, setFilterData, title}) => {
    const {id} = useParams();
    const [checkedItems, setCheckedsItems] = useState([]);
    let query = new URLSearchParams(useLocation().search);
    const navigate = useNavigate();

    useEffect(() => {
        setCheckedsItems([])
    }, [id])

    useEffect(() => {
        if(query.get('categories[]')){
            let arr = []
            for (const [key, value] of query) {
                if(key === "categories[]"){
                    arr.push(value)
                }
            }
            setCheckedsItems(arr) 
        }
    }, [query.get('categories[]')])

    const handleFilter = (mas) => {
        const categoryParams = mas.map(category => `categories[]=${category}`).join('&');

        const str = [categoryParams].filter(param => param).join('&');
      
        setFilterData(str);
        navigate({ search: str });
    }

  return (
    <div className="block block-layered-nav block-layered-nav--no-filters">
        <div className="block-title">
            <strong>
                <span>{title}</span>
            </strong>
        </div>
        <div className="block-content toggle-content">
            <dl id="narrow-by-list">
            <dt className="odd">{categories?.title}</dt>
            <dd className="odd odd2">
                <ol>
                    {categories?.map((category, index) =>
                        <Checkbox category={category} checkedItems={checkedItems} setCheckedsItems={setCheckedsItems} handleFilter={handleFilter} key={index}/>
                    )}
                </ol>
            </dd>
            </dl>
        </div>
    </div>
  );
};

export default Filter;
