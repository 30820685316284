import React from 'react'
import img1 from "../../images/banner.jpg"

const BannerCategory = ({item}) => {
  return (
    <div className='container'>
        <div className='banner-category' id={`banner${item.id}`}>
            <img src={item.image ? item.image : img1} alt={item.title} title={item.title}
                height={420}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src=img1
                }}
            />
            <div className='banner-category-container'>
                <h1>{item.title}</h1>
                <div className='text'>
                  <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BannerCategory