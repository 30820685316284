import React, { useContext, useEffect, useState } from "react";
import useFetchItems from "../../hooks/useFetchItems";
import AppServices from "../../services/general/AppServices";
import { NavLink } from "react-router-dom";
import LanguagesDropdown from "./LanguagesDropdown";
import { observer } from "mobx-react-lite";
import { Context } from '../..'

const HeaderTop = observer(() => {
    const {app} = useContext(Context);
    const [socials, setSocials] = useState();
    const [email, setEmail] = useState();

    const { 
        items,
    } = useFetchItems(AppServices.menu, "top-menu");

    useEffect(() => {
        if(app.settings){
            setSocials(app.settings.find(it => it.type === "socials")?.value)
            setEmail(app.settings.find(it => it.type === "email")?.value)
        }
    }, [app.settings])

  return (
    <div className="sns_header_top">
        <div className="container">
            <div className="sns_module">
                <div className="header-flex">
                    <div className="header-setting">
                        {app.languages?.length >= 2 && <LanguagesDropdown/>}
                    </div>
                    {socials &&
                        <ul className="header-socials">
                            {socials.map((it, index) =>
                                <li key={index}>
                                    <NavLink to={it.value} target="_blank">
                                        <i className={
                                                    it.type === "instagram" ? "fa fa-instagram"
                                                    :
                                                    it.type === "facebook" ? "fa fa-facebook"
                                                    :
                                                    it.type === "twitter" ? "fa fa-twitter"
                                                    :
                                                    it.type === "whatsapp" ? "fa fa-whatsapp"
                                                    :
                                                    "fa fa-instagram"
                                                    }
                                        ></i>  
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    }
                    {email && <div className="header-email"> 
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>}
                </div>
                <div className="header-account">
                    <div className="myaccount">
                        <div className="tongle">
                            <span>{items && items[0].title}</span>
                            <i className="fa fa-angle-down"></i>
                        </div>
                        <div className="customer-ct content">
                            <ul className="links">
                            {items && items.map((item, index) =>
                                <li key={index} className="links-item">       
                                    <NavLink to={item.value} target={item.type}>
                                        {item.title}
                                    </NavLink>    
                                    {item.items && <div className="links-sub">
                                        {item.items?.map((el, index) =>
                                            <NavLink to={el.value} className="links-sub-item" key={index}>{el.title}</NavLink>
                                        )}
                                    </div> }  
                                </li>
                            )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
})

export default HeaderTop;
