import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import ModalCustom from '../modals/ModalCustom'

const CategoriesProductCard = observer(({card}) => {
    const {app} = useContext(Context);

    const [open, setOpen] = useState();

    const handleClose = () => {
        setOpen(false)
    }

  return (
    <>
        <div className='categories-product-card'>
            {(card.subtitle && card.subtitle?.length > 1) ?
                <NavLink to={`/${app.lang}/products/${card.slug}`}>
                    {card.title}
                </NavLink>
                        :
                <div className='title'>
                    {card.title}
                </div>
            }
            <button className='button border-button' onClick={() => setOpen(true)}>{app.localizationsItems?.find_price}</button>
            <button className='button' onClick={() => setOpen(true)}>{app.localizationsItems?.product_button_app}</button>
        </div>
        {open && <ModalCustom show={open} handleClose={handleClose} productName={card.title}/>}
    </>
  )
})

export default CategoriesProductCard