import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Advantages from "../../components/complex/Advantages";
import Breadcrumbs from "../../components/complex/Breadcrumbs";
import NewsGrid from "../../components/complex/NewsGrid";
import NewsGrid2 from "../../components/complex/NewsGrid2";
import Partners from "../../components/complex/Partners";
import ProductOptions from "../../components/complex/ProductOptions";
import useFetchItems from "../../hooks/useFetchItems";
import CatalogServices from "../../services/general/CatalogServices";
import { observer } from "mobx-react-lite";
import { Context } from "../..";
import PointsBlock from "../../components/complex/PointsBlock";
import Description from "../../components/simple/Description";

const ProductPage = observer(() => {
  const {id} = useParams();
  const {app} = useContext(Context);
  
  const { 
    items, 
    loading, 
    pageCount, 
    params, 
    handlePageClick, 
    setFilterData 
  } = useFetchItems(CatalogServices.products.view, id);

  return (
    <>
        <Helmet>
            <title>{items?.title}</title>
        </Helmet>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
            :
            <>
                <Breadcrumbs title={items?.title} isProduct={true} urlName={items.parent?.title} url={`/${app.lang}/catalog/${items.parent?.slug}`}/>
                {items && <ProductOptions items={items}/>}
                    {items.elements?.map((elem, index) => 
                        <div key={index}>
                            {
                                elem.type === "advantages" ?
                                    <Advantages item={elem}/>
                                :
                                elem.type === "projects" ?
                                    <NewsGrid item={elem}/>
                                :
                                elem.type === "partners" ?
                                    <Partners item={elem}/>
                                :
                                elem.type === "articles" ?
                                    <NewsGrid2 item={elem}/>
                                :
                                elem.type === "steps" ?
                                    <PointsBlock item={elem}/>
                                :
                                elem.type === "text-information" ?
                                    <Description item = {elem}/>
                                :
                            <></>
                            }
                        </div>
                    )}
            </>
        }
    </>
  );
});

export default ProductPage;
