import http from "./http-common"
import {getData, postData} from "../handlers";

export default class CatalogServices {
    static categories = {
        index: () => {
            return getData(http, `/app/catalog/categories`);
        },
        indexIds: (queryString) => {
            let params = ''
            if(queryString){
                queryString.forEach(element => {
                    params += `ids[]=${element}&`
                });
            }
            return getData(http, `/app/catalog/categories/without-subcategories${params ? `?${params}` : ""}`);
        },
        view: (slug, queryString) => {
            return getData(http, `/app/catalog/categories/${slug}${queryString ? `?${queryString}` : ""}`);
        }
    }

    static products = {
        index: (queryString) => {
            return getData(http, `/app/catalog/products${queryString ? `?${queryString}` : ""}`);
        },

        latestProducts: () => {
            return getData(http, `/app/catalog/products`);
        },

        view: (slug, queryString) => {
            return getData(http, `/app/catalog/products/${slug}${queryString ? `${queryString}` : ""}`);
        }
    }

    static cart = {
        index: (uid) => {
            return getData(http, `/app/catalog/cart${uid ? `?uid=${uid}` : ""}`);
        },

        addToCart: (data) => {
            return postData(http, `/app/catalog/cart/addToCart`, data);
        },

        update: (data) => {
            return postData(http, `/app/catalog/cart/updateCart`, data);
        },

        remove: (data) => {
            return postData(http, `/app/catalog/cart/removeFromCart`, data);
        },

        order: (data) => {
            return postData(http, `/app/catalog/cart/order`, data);
        }
    }
}