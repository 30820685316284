import React, { useEffect, useState } from 'react'

const Checkbox = ({category, handleFilter, setCheckedsItems, checkedItems}) => {
    const [checked, setChecked] = useState();

    useEffect(() => {
        setChecked(checkedItems.find(item => item === category.slug) ? true : false)
    }, [checkedItems])

    const changeFilter = () => {
        let mas = [];
        if(checkedItems.find(it => it === category.slug)){
            let arr = checkedItems.filter(it => it !== category.slug);
            setCheckedsItems(arr);
            mas = [...arr];
        }else{
            setCheckedsItems([...checkedItems, category.slug]);
            mas = [...checkedItems, category.slug];
        }

        handleFilter(mas);
    }

  return (
    <li>
        <label onClick={() => changeFilter()} className='filter-checkbox-label'>
            <div className={checked ? 'filter-checkbox clicked' : 'filter-checkbox'} ></div>
            {category.title}
        </label>
        {category.count && <span className="count">({category.count})</span>}
    </li>
  )
}

export default Checkbox