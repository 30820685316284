import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import { NavLink } from 'react-router-dom';
import Video from '../simple/Video';
import img from "../../images/placeholder.png"

const MainOffer = ({item}) => {
    const [slides, setSlides] = useState();
    const [banners1, setBanners1] = useState();
    const [banner2, setBanner2] = useState();

    useEffect(() => {
        setSlides(item.elements?.find(item => item.type == 'slider').elements);
        setBanners1(item.elements?.filter(item => item.type == 'banner-1-col'));
        setBanner2(item.elements?.find(item => item.type == 'banner-2-col'));
    }, [item])

    const options = {
        nav: false,
        dots: true,
        loop: true,
        responsive:{
            0:{
                items:1
            }
        }
    }

  return (
    <div className='container'>
        <div className='main-offer'>
            <div className='main-offer-slider'>
                {slides && 
                    <OwlCarousel className="owl-carousel owl-theme" style={{overflow: "hidden"}} {...options}>
                            {slides.map((slide, index) => 
                                <NavLink key={index} to={slide.link}>
                                    <div className="item">
                                        <img src={slide.image ? slide.image : img} alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
                                        <div className='item-text'>
                                            <div className='item-title'>{slide.title}</div>
                                            <div>{slide.subtitle}</div>
                                        </div>
                                    </div>  
                                </NavLink>
                            )}
                    </OwlCarousel>
                }
            </div>
            <div className='flex'>
                <div className='grid'>
                    {banners1 && banners1.map((banner, index) => 
                        <div key={index}>
                            <NavLink to={banner.link}>
                                <img src={banner.image ? banner.image : img} alt=""
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; 
                                        currentTarget.src = img
                                    }}
                                />
                            </NavLink>
                        </div>
                    )}
                </div>
                {banner2 &&
                    <div>
                        <Video banner2={banner2}/>
                    </div>
                }
            </div>
        </div>
    </div>
  )
}

export default MainOffer