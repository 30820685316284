import React, { useContext } from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import Utils from '../../services/utils';
import BasketItem from '../simple/BasketItem';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const BasketContainer = observer(({items, uid, fetchItems}) => {
    const {app} = useContext(Context);

    const [total, setTotal] = useState();

    useEffect(() => {
        if(items?.products){
            let count = items.products.reduce(
                (prev, item) => prev + (Number(item.price) * Number(item.count)), 0
            )
            setTotal(count)
        }
    }, [items])

  return (
        <div id="sns_content" className="wrap layout-m">
            <div className="container">
                <div className="row">
                    <div className="shoppingcart">
                        <div className="sptitle col-md-12">
                            <h3>{app.localizationsItems?.cart}</h3>
                            <h4 className="style">{app.localizationsItems?.proceed_checkout}</h4>
                        </div>
                        <div className="content col-md-12">
                            <ul className="title clearfix">
                                <li className="text1">{app.localizationsItems?.product_name}</li>
                                <li className="text2">{app.localizationsItems?.price}</li>
                                <li className="text2">{app.localizationsItems?.count}</li>
                                <li className="text2">{app.localizationsItems?.total}</li>
                            </ul>

                            {items?.products?.map((card, index) =>
                                <BasketItem card={card} key={index} uid={uid} fetchItems={fetchItems}/>
                            )}

                            {/*<ul className="nav-bot clearfix">
                                <li className="continue"><NavLink to={``}>{app.localizationsItems?.continue_shopping}</NavLink></li>
                                <li className="clear"><a href="#">{app.localizationsItems?.clear_cart}</a></li>
                            </ul>*/}
                            <div className="row justify-content-end">
                                <form className="form-right col-md-4">
                                    <div className="form-bd">
                                        <h3>
                                            <span className="text3">{app.localizationsItems?.total}:</span>
                                            <span className="text4">{Utils.numberWithSpaces(total)} {app.localizationsItems?.currency}</span>
                                        </h3>
                                        <NavLink to={`/${app.lang}/checkout`} className="style-bd">{app.localizationsItems?.proceed_checkout}</NavLink>
                                        <p className="checkout">{app.localizationsItems?.checkout_text}</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
})

export default BasketContainer