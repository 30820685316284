import React, { useContext } from 'react'
import Utils from '../../services/utils'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import UserServices from '../../services/general/userServices';

const BasketItem = observer(({card}) => {
    const [count, setCount] = useState(card.count);
    const {app, user} = useContext(Context);

    const changeCount = async (e) => {
        setCount(Number(e.target.value))
        if(e.target.value > 0){
            const data = {
                uid: user.uid,
                product_id: card.id,
                count: Number(e.target.value)
            }
    
            const res = await UserServices.cart.update(data);

            if(res.statusCode === 200){
                setCount(e.target.value);
                user.setBasketChange(Math.random().toString(16))
            }
        }
    }

    const plusCount = async () => {
        const data = {
            uid: user.uid,
            product_id: card.id,
            count: count+1
        }

        const res = await UserServices.cart.update(data);

        if(res.statusCode === 200){
            setCount(count+1)
            user.setBasketChange(Math.random().toString(16))
        }
    }

    const minusCount = async () => {
        if(count > 1){
            const data = {
                uid: user.uid,
                product_id: card.id,
                count: count-1
            }
    
            const res = await UserServices.cart.update(data);
    
            if(res.statusCode === 200){
                setCount(count-1);
                user.setBasketChange(Math.random().toString(16));
            }
        }
    }

    const deleteItem = async () => {
        const data = {
            uid: user.uid,
            product_id: card.id
        }

        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16));
        }
    }


  return (
    <ul className="nav-mid clearfix">
        <li className="image"><NavLink to={`/${app.lang}/products/${card.slug}`}><img src={card.image} alt=""/></NavLink></li>
        <li className="item-title">
            <NavLink to={`/${app.lang}/products/${card.slug}`}>
                {card.title}
            </NavLink>
        </li>
        <li className='item-fields'>
            {card.fields?.map((field, index) =>
                <span key={index}>{field.name}: {field.value}; </span>
            )}
            -
        </li>
        <li className="price1">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</li>
        <li className="number">
            <div className="qty-container">
                <button className="qty-decrease" type="button" onClick={minusCount}></button>
                <input className="input-text qty" type="number" value={count}
                    onChange={e => changeCount(e)}
                />
                <button className="qty-increase" type="button" onClick={plusCount}></button>
            </div>
        </li>
        <li className="price2">{Utils.numberWithSpaces(Number(card.price) * Number(card.count))} {app.localizationsItems?.currency}</li>
        <li className="icon2"><i className="btn-remove fa fa-remove" onClick={deleteItem}></i></li>
    </ul>
  )
})

export default BasketItem