import React, { useState } from 'react'
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"
import { NavLink } from 'react-router-dom';

const Video = ({banner2}) => {
  return (
    <NavLink to={banner2.url}>
        <div className='banner-video'>
                <img src={banner2.link}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; 
                        currentTarget.src = img
                    }}
                />
            <div className={'video-text'}>
                <div>{banner2.title}</div>
                <p>{banner2.subtitle}</p>
            </div>
        </div>
    </NavLink>
  )
}

export default Video