import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import Advantages from "../../components/complex/Advantages";
import BannerCategory from "../../components/complex/BannerCategory";
import CategoryGrid from "../../components/complex/CategoryGrid";
import NewsGrid from "../../components/complex/NewsGrid";
import NewsGrid2 from "../../components/complex/NewsGrid2";
import Partners from "../../components/complex/Partners";
import PointsBlock from "../../components/complex/PointsBlock";
import useFetchItems from "../../hooks/useFetchItems";
import CatalogServices from "../../services/general/CatalogServices";
import Description from "../../components/simple/Description";
import { useEffect } from "react";
import { Context } from "../..";
import { observer } from "mobx-react-lite";

const CategoryPage = observer(() => {
    const {id} = useParams();
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2) {
                navigate(`/${app.lang}/catalog/${id}`)
            }
        }
    }, [app.lang])

    const { 
        items, 
        loading
    } = useFetchItems(CatalogServices.categories.view, id);

  return (
    <>
      {loading ?
        <div className="isLoading"></div>
      :
        <>
            <Helmet>
                <title>{items.title}</title>
            </Helmet>
            <BannerCategory item={items}/>
            {items.elements?.map((elem, index) => 
                <div key={index}>
                    {
                        elem.type === "advantages" ?
                            <Advantages item={elem}/>
                        :
                        elem.type === "projects" ?
                            <NewsGrid item={elem}/>
                        :
                        elem.type === "partners" ?
                            <Partners item={elem}/>
                        :
                        elem.type === "articles" ?
                            <NewsGrid2 item={elem}/>
                        :
                        elem.type === "steps" ?
                            <PointsBlock item={elem}/>
                        :
                        elem.type === "text-information" ?
                            <Description item = {elem}/>
                        :
                        elem.type === "products" ?
                            <CategoryGrid item={items}/>
                        :
                    <></>
                    }
                </div>
            )}
        </>
      }
    </>
  );
});

export default CategoryPage;