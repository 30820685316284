import React from "react";
import Advantages from "./Advantages";
import NewsGrid from "./NewsGrid";
import NewsGrid2 from "./NewsGrid2";
import Partners from "./Partners";
import PointsBlock from "./PointsBlock";
import Gallery from "./Gallery";
import Description from "../simple/Description";

const Article = ({item}) => {
  return (
    <div id="sns_content" className="wrap">
        <div className="container">
            <div className="row justify-content-center">
                <div id="sns_main" className="col-md-9 col-main">
                    <div id="sns_mainmidle">
                        <div className="blogs-page">
                            <div className="postWrapper v1">
                                <div className="post-img">
                                    <img src={item.image} alt="" />
                                </div>
                                <div className="post-title">
                                    <h1>
                                    {item.title}
                                    </h1>
                                </div>
                                <div className="post-content">
                                    <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                </div>
                            </div>
                        </div>
                        {item.elements?.map((elem, index) => 
                            <div key={index}>
                                    {
                                    elem.type === "advantages" ?
                                        <Advantages item={elem}/>
                                    :
                                    elem.type === "projects" ?
                                        <NewsGrid item={elem}/>
                                    :
                                    elem.type === "partners" ?
                                        <Partners item={elem}/>
                                    :
                                    elem.type === "articles" ?
                                        <NewsGrid2 item={elem}/>
                                    :
                                    elem.type === "steps" ?
                                        <PointsBlock item={elem}/>
                                    :
                                    elem.type === "gallery" ?
                                        <Gallery item={elem}/>
                                    :
                                    elem.type === "text-information" ?
                                        <Description item = {elem}/>
                                    :
                                    <></>
                                    }
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Article;
