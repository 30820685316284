import Article from "../components/complex/Article";
import Articles from "../pages/blog/Articles";
import BlogDetail from "../pages/blog/BlogDetail";
import Cart from "../pages/cart/Cart";
import Checkout from "../pages/cart/Checkout";
import Catalog from "../pages/category/Catalog";
import CatalogCategory from "../pages/category/CatalogCategory";
import CategoryPage from "../pages/category/CategoryPage";
import ErrorPage from "../pages/error/ErrorPage";
import MainPage from "../pages/main/MainPage";
import SamplePage from "../pages/main/SamplePage";
import ProductPage from "../pages/products/ProductPage";
import ProjectDetail from "../pages/project/ProjectDetail";
import Projects from "../pages/project/Projects";
import SearchPage from "../pages/search/SearchPage";
import ServiceDetail from "../pages/services/ServiceDetail";
import Services from "../pages/services/Services";
import {ARTICLE_DETAIL_PAGE_ROUTE, ARTICLE_PAGE_ROUTE, CART_PAGE_ROUTE, CATALOG_CATEGORY_PAGE_ROUTE, CATALOG_PAGE_ROUTE, CATEGORY_PAGE_ROUTE, CHECKOUT_PAGE_ROUTE, ERROR_PAGE_ROUTE, MAIN_PAGE_ROUTE, PRODUCT_DETAIL_PAGE_ROUTE, PROJECTS_PAGE_ROUTE, PROJECT_DETAIL_PAGE_ROUTE, SAMPLE_PAGE_ROUTE, SEARCH_CATEGORY_PAGE_ROUTE, SERVICES_DETAIL_PAGE_ROUTE, SERVICES_PAGE_ROUTE } from "./Constants";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: MainPage
    },
    /*{
        path: CATEGORY_PAGE_ROUTE,
        Component: CategoryPage
    },*/
    {
        path: CATALOG_CATEGORY_PAGE_ROUTE,
        Component: CatalogCategory
    },
    {
        path: PRODUCT_DETAIL_PAGE_ROUTE,
        Component: ProductPage
    },
    {
        path: ARTICLE_DETAIL_PAGE_ROUTE,
        Component: BlogDetail
    },
    {
        path: PROJECT_DETAIL_PAGE_ROUTE,
        Component: ProjectDetail
    },
    {
        path: SAMPLE_PAGE_ROUTE,
        Component:SamplePage
    },
    {
        path: ERROR_PAGE_ROUTE,
        Component: ErrorPage
    },
    {
        path: PROJECTS_PAGE_ROUTE,
        Component: Projects
    },
    {
        path: ARTICLE_PAGE_ROUTE,
        Component: Articles
    },
    {
        path: SERVICES_PAGE_ROUTE,
        Component: Services
    },
    {
        path: SERVICES_DETAIL_PAGE_ROUTE,
        Component: ServiceDetail
    },
    {
        path: CART_PAGE_ROUTE,
        Component: Cart
    },
    {
        path: CHECKOUT_PAGE_ROUTE,
        Component: Checkout
    },
    {
        path: CATALOG_PAGE_ROUTE,
        Component: Catalog
    },
    {
        path: SEARCH_CATEGORY_PAGE_ROUTE,
        Component: SearchPage
    }
]