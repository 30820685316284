import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/AppServices';
import SearchProducts from '../../components/complex/SearchProducts';
import BannerCategory from '../../components/complex/BannerCategory';

const SearchPage = observer(() => {
    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'catalog')

  return (
    <>
        {loading ?
            <div className="isLoading"></div>
            :        
            <div>
                <BannerCategory item={items}/>
                <SearchProducts/>
            </div>
        }
    </>
  )
})

export default SearchPage