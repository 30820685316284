import React, { useContext, useEffect, useState } from "react";
import useFetchItems from "../../hooks/useFetchItems";
import AppServices from "../../services/general/AppServices";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { NavLink } from "react-router-dom";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import Widgets from "../simple/Widgets";

const Footer = observer(() => {
    const {app} = useContext(Context);

    const {handleSubmit, register, reset, control, formState: {errors}} = useForm();

    const [loading, setLoading] = useState();
    const [visible, setVisible] = useState();
    const [socials, setSocials] = useState();
    const [widgets, setWidgets] = useState();

    const { 
        items,
    } = useFetchItems(AppServices.menu, "bottom-menu");

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await AppServices.request(data);

        if (res.statusCode === 200) {
        setVisible(true);

        setTimeout(() => {
            setVisible(false)
            reset();
        }, 3000);
        }

        setLoading(false);
    }

    useEffect(() => {
        if(app.settings){
            setSocials(app.settings.find(it => it.type === "socials")?.value)
            setWidgets(app.settings.find(it => it.type === "widgets")?.value);
        }
    }, [app.settings])

  return (
    <div id="sns_footer" className="footer_style vesion2 wrap">
        <div id="sns_footer_top" className="footer">
            <div className="container">
                <div className="container_in">
                    <div className="row justify-content-between">
                        {items && 
                            items.map((item, index) => 
                            <div className="col-phone-12 col-xs-6 col-sm-3 col-md-2 column column1" key={index}>
                                <h6>{item.title}</h6>
                                <ul>
                                {item.items?.map((elem, index) => 
                                    <li key={index}>
                                    <NavLink to={elem.value} target={elem.type}>{elem.title}</NavLink>
                                    </li>
                                )}
                                </ul>
                            </div>
                            )
                        }
                        <div className="col-phone-12 col-xs-6 col-sm-3 col-md-3 column column4">
                            <div className="subcribe-footer">
                            <div className="block_border block-subscribe">
                                <div className="block_head">
                                <h6>{app.localizationsItems?.application_send}</h6>
                                <p>{app.localizationsItems?.application_buy}</p>
                                </div>
                                <form id="newsletter-validate-detail" onSubmit={handleSubmit(onSubmit)}>
                                <div className="block_content">
                                    <div className="input-box">
                                        <div className="input_warp">
                                            <Controller name="phone" control={control} defaultValue=""
                                            render={({field: {onChange, value}}) => (
                                                <ReactInputMask  mask="+7(999)999-99-99" maskChar="_"
                                                                onChange={onChange} value={value}
                                                >
                                                    {inputProps => (
                                                        <input {...inputProps} type="tel" className="input-text required-entry validate-email" placeholder="Номер телефона"
                                                        />
                                                    )}
                                                </ReactInputMask>
                                            )}
                                            rules={{required: true, pattern: {
                                                value: /(\+7|8)[- _]*\(?[- _]*(\d{3}[- _]*\)?([- _]*\d){7}|\d\d[- _]*\d\d[- _]*\)?([- _]*\d){6})/g
                                            }}}
                                            />
                                            {errors.phone && errors.phone.type === 'required' &&
                                            <div className="error-text">{app.localizationsItems?.required_field}</div>}
                                            {errors.phone && errors.phone.type === 'pattern' &&
                                            <div className="error-text">{app.localizationsItems?.incorrect_value}</div>}
                                        </div>
                                        <div className="button_warp">
                                            <button
                                            className="button gfont"
                                            title="Subcribe"
                                            type="submit"
                                            >
                                            <span>
                                                <span>{app.localizationsItems?.call_me}</span>
                                            </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </form>
                            </div>
                            </div>
                            <div className={visible ? "footer-modal footer-modal-visible" : "footer-modal"}>
                                <i className="fa fa-check"></i>
                                <h4>{app.localizationsItems?.application_thanks}</h4>
                                <div>
                                    {app.localizationsItems?.application_content}
                                </div>
                            </div>
                            <div className="footer-socials">     
                                <span>{app.localizationsItems?.footer_socials}</span>          
                                {socials &&
                                    <ul className="header-socials">
                                        {socials.map((it, index) =>
                                            <li key={index}>
                                                <NavLink to={it.value} target="_blank">
                                                    <i className={
                                                                it.type === "instagram" ? "fa fa-instagram"
                                                                :
                                                                it.type === "facebook" ? "fa fa-facebook"
                                                                :
                                                                it.type === "twitter" ? "fa fa-twitter"
                                                                :
                                                                it.type === "whatsapp" ? "fa fa-whatsapp"
                                                                :
                                                                "fa fa-instagram"
                                                                }
                                                    ></i>  
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {widgets && <Widgets item={widgets}/>}
        </div>
        <div id="sns_footer_bottom" className="footer">
            <div className="container">
                <div className="row">
                    <div className="bottom-pd1 col-sm-6">
                        <div className="sns-copyright">
                            © Panama.kz
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
})

export default Footer;
