import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../..';
import { observer } from 'mobx-react-lite';
import CatalogServices from '../../services/general/CatalogServices';
import useFetchItems from '../../hooks/useFetchItems';
import SubCategories from '../../components/complex/SubCategories';
import CategoriesProducts from '../../components/complex/CategoriesProducts';
import Description from '../../components/simple/Description';
import PointsBlock from '../../components/complex/PointsBlock';
import NewsGrid2 from '../../components/complex/NewsGrid2';
import Partners from '../../components/complex/Partners';
import NewsGrid from '../../components/complex/NewsGrid';
import Advantages from '../../components/complex/Advantages';
import SubCategoryBanner from '../../components/simple/SubCategoryBanner';
import CategoryBreadcrumbs from '../../components/simple/CategoryBreadcrumbs';

const CatalogCategory = observer(() => {
    const {app} = useContext(Context);

    const {slug} = useParams();
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2) {
                navigate(`/${app.lang}/catalog/${slug}`)
            }
        }
    }, [app.lang])

    const { 
        items, 
        loading
    } = useFetchItems(CatalogServices.categories.view, slug);

  return (
    <>
        {loading ?
            <div className="isLoading"></div>
            :        
            <div>
                <CategoryBreadcrumbs items={items}/>
                <SubCategoryBanner items={items}/>
                <SubCategories categories={items.children}/>
                <CategoriesProducts categoryId={items.id}/>
                {items.elements?.map((elem, index) => 
                    <div key={index}>
                        {
                            elem.type === "advantages" ?
                                <Advantages item={elem}/>
                            :
                            elem.type === "projects" ?
                                <NewsGrid item={elem}/>
                            :
                            elem.type === "partners" ?
                                <Partners item={elem}/>
                            :
                            elem.type === "articles" ?
                                <NewsGrid2 item={elem}/>
                            :
                            elem.type === "steps" ?
                                <PointsBlock item={elem}/>
                            :
                            elem.type === "text-information" ?
                                <Description item = {elem}/>
                            :
                        <></>
                        }
                    </div>
                )}
            </div>
        }
    </>
  )
})

export default CatalogCategory