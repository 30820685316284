import {useCallback, useEffect, useState} from "react";
import { ERROR_PAGE_ROUTE } from "../navigation/Constants";
import { useNavigate } from "react-router-dom";

const useFetchInfo = (method, id, additionalData) => {
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState();
    const navigate = useNavigate();

    const fetchInfo = useCallback(async () => {
        setLoading(true);
        
        const res = additionalData ? await method(id, additionalData) : await method(id);

        if (res.statusCode === 200) {
            setInfo(res.content)
        }
        if(res.statusCode === 404){
            navigate(ERROR_PAGE_ROUTE)
        }

        setLoading(false);
    }, [id, method])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchInfo()
    }, [fetchInfo]);

    return {
        info, loading, fetchInfo
    }
}

export default useFetchInfo