import React, { useContext } from "react";
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from "react-router-dom";
import useFetchItems from "../../hooks/useFetchItems";
import ProjectServices from "../../services/general/ProjectServices";
import NewsCard from "../simple/NewsCard";
import { observer } from "mobx-react-lite";
import { Context } from "../..";

const NewsGrid = observer(({item, title}) => {
  const navigate = useNavigate();
  const {app} = useContext(Context);

  const { 
    items, 
    loading, 
    pageCount, 
    params, 
    handlePageClick, 
    setFilterData 
  } = useFetchItems(ProjectServices.index);

    const options = {
        nav: true,
        dots: false,
        loop: true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:2
            },
            1000:{
                items:3
            }
        }
    }

  return (
    items?.items?.length >=1 &&
    <div className="container">
        <div className="sns-latestblog">
            <div className="row">
                <div className="col-md-12">
                    <div className="block-title">
                        <h3>{title ? title : item?.title}</h3>
                    </div>
                </div>
                <OwlCarousel
                    id="latestblog132"
                    className="latestblog-content owl-carousel owl-theme"
                    style={{display: "inline-block"}}
                    {...options}
                >
                    {items.items.map((item, index) => 
                        <NewsCard item={item} key={index} onClick={() => {navigate(`/${app.lang}/projects/${item.slug}`)}}/>
                    )}
                </OwlCarousel>
            </div>
        </div>
    </div>
  );
})

export default NewsGrid;
