import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Utils from '../../services/utils';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import UserServices from '../../services/general/userServices';

const DropDownCart = observer(() => {
    const {app, user} = useContext(Context);

    const navigate = useNavigate();

    const [total, setTotal] = useState(0);

    const fetchItems = async () => {
        const res = user.uid ? await UserServices.cart.index(user.uid) : await UserServices.cart.index();

        if(res.statusCode === 200){
            res.content?.products ? user.setCartProducts(res.content.products) : user.setCartProducts([])
            res.content?.total_price ? user.setTotalSum(res.content.total_price) : user.setTotalSum(0)

            if(res.content?.uid && (res.content?.uid !== user.uid)){
                user.setUid(res.content?.uid)
            }
        }
    }

    useEffect(() => {
        fetchItems() 
    }, [user.basketChange])

    const deleteItem = async (product) => {
        //setLoading(true);
        const data = {
            uid: user.uid,
            product_id: product.id
        }
        
        const res = await UserServices.cart.remove(data);

        if(res.statusCode === 200){
            user.setBasketChange(Math.random().toString(16))
            //setLoading(false);
        }
    }

  return (
    <div className="top-cart">
        <div className="mycart mini-cart">
            <div className="block-minicart">
                <div className="tongle">
                    <i className="fa fa-shopping-cart"></i>
                    <div className="summary">
                        <span className="amount">
                            <NavLink to={`/${app.lang}/cart`}>
                                <span>{user.cartProducts?.length ? user.cartProducts.length : 0}</span>
                                ( {app.localizationsItems?.items} )
                            </NavLink>
                        </span>
                    </div>
                </div>
                <div className="block-content content">
                    <div className="block-inner">
                        <ol id="cart-sidebar" className="mini-products-list">
                            {user.cartProducts?.map((product, index) =>
                                <li className="item odd" key={index}>
                                    <NavLink className="product-image" to={`/${app.lang}/products/${product.slug}`}>
                                        <img alt="" src={product.image}/>
                                    </NavLink>
                                    <div className="product-details">
                                        <button className="btn-remove" onClick={() => deleteItem(product)}></button>
                                        <p className="product-name">
                                            <NavLink to={`/${app.lang}/products/${product.slug}`}>{product.title}</NavLink>
                                        </p>
                                        {product.count} x 
                                        <span className="price"> {Utils.numberWithSpaces(product.price)} {app.localizationsItems?.currency}</span>
                                        <ul>
                                            {product?.fields?.map((triat, index) =>
                                                <li key={index} className='mb-0'>{triat.name}: {triat.value}</li>
                                            )}
                                        </ul>
                                    </div>
                                </li>
                            )}

                        </ol>
                        <p className="cart-subtotal">
                            <span className="label">{app.localizationsItems?.total}:</span>
                            <span className="price">{Utils.numberWithSpaces(total)} {app.localizationsItems?.currency}</span>
                        </p>
                        <div className="actions">
                            <NavLink className="button" to={`/${app.lang}/checkout`}>
                                <span>
                                    <span>{app.localizationsItems?.сheckout}</span>
                                </span>
                            </NavLink>
                            <NavLink className="button gfont go-to-cart" to={`/${app.lang}/cart`}>{app.localizationsItems?.cart}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default DropDownCart