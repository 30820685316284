import React, { useContext, useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import Breadcrumbs from "../../components/complex/Breadcrumbs";
import { Helmet } from 'react-helmet';
import AppServices from '../../services/general/AppServices';
import ArticlesGrid from '../../components/complex/ArticlesGrid';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Articles = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2) {
                navigate(`/${app.lang}/articles`)
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'articles')

  return (
    <>
        <Helmet>
            <title>{items?.title}</title>
        </Helmet>
        {loading ?
            <div className="preloader">
                <div className="isLoading"></div>
            </div>
        :
            <div>
                <Breadcrumbs title={items.title}/>
                <ArticlesGrid/>
            </div>
        }

    </>
  )
})

export default Articles