import React, { useEffect, useState } from 'react'
import Utils from '../../services/utils';
import ModalCustom from './ModalCustom';

const PopupModal = () => {
    const [show, setShow] = useState();

    useEffect(() => {
        if(!Utils.getCookie('popup-modal')){
            setShow(true);
            Utils.setCookie('popup-modal', 'true', {secure: true, 'max-age': 86400});
        }
    }, [])

    const handleClose = () => setShow(false);
  return (
    <div>
        {show && <ModalCustom show={show} handleClose={handleClose}/>}
    </div>
  )
}

export default PopupModal