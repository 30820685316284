import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Error = observer(() => {
    const {app} = useContext(Context);
  return (
    <div id="sns_content" className="wrap layout-m">
        <div className="container">
            <div className="row">
                <div className="content">
                    <h1>404</h1>
                    <h2>{app.localizationsItems?.not_found}</h2>
                    <p>{app.localizationsItems?.not_found_content}</p>
                </div>
            </div>
        </div>
    </div>
  )
})

export default Error