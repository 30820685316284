import {useState} from 'react';

const useUid = () => {
    const getToken = () => {
        return localStorage.getItem('uid');
    };

    const [uid, setUid] = useState(getToken());

    const saveToken = cartUid => {
        localStorage.setItem('uid', cartUid);
        setUid(cartUid)
    };

    const removeToken = () => {
        localStorage.removeItem('uid');
        setUid('');
    };

    return {
        setUid: saveToken,
        removeUid: removeToken,
        uid
    }
};

export default useUid;