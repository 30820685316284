import React, { useContext } from 'react'
import useFetchItems from '../../hooks/useFetchItems';
import CatalogServices from '../../services/general/CatalogServices';
import Pagination from './Pagination';
import CategoriesProductCard from '../simple/CategoriesProductCard';
import { Context } from '../..';
import { observer } from 'mobx-react-lite';

const CategoriesProducts = observer(({categoryId}) => {
    const {app} = useContext(Context);

    const { 
        items, 
        loading,
        pageCount,
        params,
        handlePageClick,
        setFilterData
    } = useFetchItems(CatalogServices.products.index, null, `category_id=${categoryId}`);

  return (
    <>
       
            <div className={loading ? 'container isLoading' : 'container'}>
                {items?.items?.length >= 1 &&
                    <div className='categories-product-list'>
                        <div className='categories-product-title'>
                            <div>{app.localizationsItems?.product_name}</div>
                            <div>{app.localizationsItems?.product_price}</div>
                            <div>{app.localizationsItems?.product_buy}</div>
                        </div>
                        {items?.items?.map((card, index) =>
                            <CategoriesProductCard card={card} key={index}/>
                        )}
                    </div>
                }
                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            </div>
        
    </>
  )
})

export default CategoriesProducts