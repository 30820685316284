import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap';
import AddToCart from '../simple/AddToCart';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import img from "../../images/placeholder.png"

const ToCartModal = observer(({show, setShow, item}) => {
    const handleClose = () => setShow(false);
    
    const {app} = useContext(Context);

  return (
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <div>
                <div className='product-wrapper'>
                    <img src={item?.image ? item?.image : img} alt="" 
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = img
                        }}
                    />
                    <NavLink to={`${app.lang}/products/${item?.slug}`}>
                        <h4>{item?.title}</h4>
                    </NavLink>
                </div>
                <AddToCart card={item}/>
            </div>
        </Modal.Body>
    </Modal>
  )
})

export default ToCartModal