import React from 'react'
import Utils from '../../services/utils'
import img from "../../images/placeholder.png"

const NewsCard2 = ({item, data, ...props}) => {
  return (
    <div className="item" {...props}>
        <div className={data ? "banner5 banner-service" : "banner5"}>
            <img src={item.image ? item.image : img} alt="" 
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src = img
                }}
            />
        </div>
        <div className="blog-page">
            <div className="blog-right">
                <div className="style1">
                    {!data && <p dangerouslySetInnerHTML={{ __html: Utils.generateDate(item.date) }}></p>}
                </div>
                <p className="style2">{item.title}</p>
                <p className="style3">
                    {item.description}
                </p>
            </div>
        </div>
    </div>
  )
}

export default NewsCard2