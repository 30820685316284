import React, { useContext } from 'react'
import img1 from "../../images/placeholder.png"
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const CategoryCard = observer(({card}) => {
    const {app} = useContext(Context);

  return (
    <NavLink className='category-card' to={`/${app.lang}/catalog/${card.slug}`}>
        <img src={card.image ? card.image : img1} alt={card.title} title={card.title} 
            width={277}
            height={222}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; 
                currentTarget.src=img1
            }}
        />
        <div className='back-img'></div>
        <div className='category-card-title'>
            {card.title}
        </div>
    </NavLink>
  )
})

export default CategoryCard