import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CatalogServices from "../../services/general/CatalogServices";
import Filter from "./Filter";
import Pagination from "./Pagination";
import ProductCard from "./ProductCard";
import { observer } from "mobx-react-lite";

const CategoryGrid = observer(({item}) => {
    const navigate = useNavigate();
    const [show, setShow] = useState();

    let query = new URLSearchParams(useLocation().search);
    const [pageCount, setPageCount] = useState();
    const [params, setParams] = useState(query);
    const [loading, setLoading] = useState();
    const [items, setItems] = useState();

    const fetchProducts = async () => {
        setLoading(true);
        let str2 = query.toString();
        let str3 = item.slug === 'catalog' ? '' : `category_id=${item.id}&`

        const res = await CatalogServices.products.index(`${str3}${str2}`);

        if(res.statusCode === 200){
            setItems(res.content);
            setPageCount(res.content?.total_pages);
        }
        setLoading(false);
    }

    useEffect(() => {
        if(!query.get('page')){
            params.set('page', 1);
        }
        fetchProducts()
    }, [params, query.get('categories[]')])


    const handlePageClick = (data) => {
        query.set('page', data.selected + 1);
        setParams(query);
        
        navigate({
            search: query.toString()
        });
    };

    const setFilterData = (filterString) => {
        let newParams = new URLSearchParams(filterString);
        if (query.get('order')) {
            newParams.set('order', query.get('order'))
        }
        setParams(newParams);
    };

  return (
    <div id="sns_content" className={show ? "wrap layout-lm show-sidebar" : "wrap layout-lm"}>
        <div className={loading ? "container isLoading" : "container"}>
                <div className="row">
                    {item.children &&<div id="sns_left" className="col-md-3">
                            <div className="wrap-in">
                                <Filter categories={item.children} setFilterData={setFilterData} productId={item.id} title={item.title}/>
                            </div>
                        </div>
                    }
                    {show && <div className="back-filter-none" onClick={() => setShow(false)}></div>}
                    <div id="sns_main" className={item.children ? "col-md-9 col-sm-12 col-main" : "col-md-12 col-sm-12 col-main"}>
                        <div id="sns_mainmidle">
                            <div className="category-products">
                                <div className="category-filter" onClick={() => setShow(true)}>
                                    <i className="fa fa-align-justify"></i>
                                </div>
                                <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
                                <div className="sns-products-container clearfix">
                                    <div className="products-grid row style_grid">
                                        {items && items.items.map((item, index) => 
                                            <div className="item col-lg-3 col-md-4 col-sm-4 col-xs-6 col-phone-12" key={index}>
                                                <ProductCard item={item}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </div>
    </div>
  );
});

export default CategoryGrid;
