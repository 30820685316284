import {makeAutoObservable} from "mobx";

export default class AppStore {
    constructor() {
        this._settings = []
        this._localizationsItems = {}
        this._lang = 'ru'
        this._languages = []
        this._userCabinet = true
        this._bonuses = false
        this._minimumOrderAmount = null
        this._cartEnabled = false
        makeAutoObservable(this)
    }

    setCartEnabled(cartEnabled) {
        this._cartEnabled = cartEnabled
    }

    setSettings(settings) {
        this._settings = settings
    }

    setLocalizationsItems(localizationsItems) {
        this._localizationsItems = localizationsItems
    }

    setLang(lang) {
        localStorage.setItem('i18nextLng', lang)
        this._lang = lang
    }

    setLanguages(languages) {
        this._languages = languages
    }

    setUserCabinet(userCabinet) {
        this._userCabinet = userCabinet
    }

    setBonuses(bonuses) {
        this._bonuses = bonuses
    }

    setMinimumOrderAmount(minimumOrderAmount) {
        this._minimumOrderAmount = minimumOrderAmount
    }

    get cartEnabled() {
        return this._cartEnabled
    }

    get settings() {
        return this._settings
    }

    get localizationsItems() {
        return this._localizationsItems
    }

    get lang() {
        return this._lang
    }

    get languages() {
        return this._languages
    }

    get userCabinet() {
        return this._userCabinet
    }

    get minimumOrderAmount() {
        return this._minimumOrderAmount
    }
    
    get bonuses() {
        return this._bonuses
    }

}