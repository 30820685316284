import React, { useContext } from "react";
import useFetchItems from "../../hooks/useFetchItems";
import CatalogServices from "../../services/general/CatalogServices";
import OwlCarousel from "react-owl-carousel";
import ProductCard from "./ProductCard";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Context } from "../..";

const RelatedProducts = observer(() => {
  const navigate = useNavigate();
  const {id} = useParams();

  const {app} = useContext(Context);

  const { 
    items, 
    loading, 
    pageCount, 
    params, 
    handlePageClick, 
    setFilterData 
  } = useFetchItems(CatalogServices.products.index, {queryString: `product_id=${id}&product_attachment=relevant`});

  const options2 = {
    nav: true,
    dots: false,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  return (
    <div className="products-related">
        <div className="detai-products1">
            <div className="title">
                <h3>{app.localizationsItems?.related_products}</h3>
            </div>
            <div className="products-grid">
                {items && (
                    <OwlCarousel
                        id="related_upsell1"
                        className="item-row owl-carousel owl-theme"
                        style={{ display: "inline-block" }}
                        {...options2}
                    >
                    {
                        items.items.map((item, index) => (
                        <div className="item" key={index}>
                            <ProductCard item={item} />
                        </div>
                        ))}
                    </OwlCarousel>
                )}
            </div>
        </div>
    </div>
  );
});

export default RelatedProducts;
