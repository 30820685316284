import React, { useContext } from "react";
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from "react-router-dom";
import useFetchItems from "../../hooks/useFetchItems";
import CatalogServices from "../../services/general/CatalogServices";
import ProductCard from "./ProductCard";
import { observer } from "mobx-react-lite";
import { Context } from "../..";

const UpsellProducts = observer(({id}) => {
  const navigate = useNavigate();

  const {app} = useContext(Context);

    const { 
        items, 
        loading, 
        pageCount, 
        params, 
        handlePageClick, 
        setFilterData 
      } = useFetchItems(CatalogServices.products.index, {queryString: `product_id=${id}&product_attachment=recomend`});

    const options = {
        nav: true,
        dots: false,
        loop: true,
        responsive:{
            0:{
                items:1
            },
            480:{
                items:2
            },
            768:{
                items:3
            },
            1200:{
                items:4
            }
        }
    }

  return (
    <div className="products-upsell">
      <div className="detai-products1">
        <div className="title">
          <h3>{app.localizationsItems?.also_interested}</h3>
        </div>
        <div className="products-grid">
            {items &&
                <OwlCarousel
                    id="related_upsell"
                    className="item-row owl-carousel owl-theme"
                    style={{ display: "inline-block" }}
                    {...options}
                >
                   {items.items.map((item, index) => (
                        <div className="item" key={index}>
                            <ProductCard item={item} />
                        </div>
                    ))}
                </OwlCarousel>
            }
        </div>
      </div>
    </div>
  );
});

export default UpsellProducts;
