import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const SearchMenu = observer(() => {
    const [term, setTerm] = useState('');
    const navigate = useNavigate();

    const {app} = useContext(Context);

    const searchOn = () => {
        navigate(`/${app.lang}/catalog/search?term=${term}`)
    }

  return (
    <>
            <span className="icon-search"></span>
            <div className="top-search">
                <div
                    id="sns_serachbox_pro11739847651442478087"
                    className="sns-serachbox-pro"
                >
                <div className="sns-searbox-content">
                    <div>
                    <div className="form-search">
                        <input
                        id="search3703138361442478087"
                        className="input-text"
                        type="text"
                        placeholder="Поиск...."
                        size="30"
                        autoComplete="off"
                        value={term}
                        onChange={e => setTerm(e.target.value)}
                        />
                        <button
                        className="button form-button"
                        title="Search"
                        onClick={() => searchOn()}
                        >
                        {app.localizationsItems?.search}
                        </button>
                        <div
                        id="search_autocomplete3703138361442478087"
                        className="search-autocomplete"
                        style={{display: "none"}}
                        ></div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
    </>
  )
})

export default SearchMenu