import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import UserServices from '../../services/general/userServices';

const AddToCart = observer(({card}) => {
    const [count, setCount] = useState(1);
    const {app, user} = useContext(Context);

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const [visible, setVisible] = useState(false);

    const changeCount = (e) => {
        setCount(e.target.value)
    }

    const plusCount = () => {
        setCount(count+1)
    }

    const minusCount = () => {
        if(count > 1){
            setCount(count-1)
        }
    }

    const addToBasket = async(data) => {
        setVisible(true);

        let newData = {
            uid: user.uid ? user.uid : null,
            product_id: card.id,
            count: count,
            fields: []
        }

        for(var key in data){
            newData.fields.push(
                {name: key, value: data[key]}
            )
        }
          
        const res = await UserServices.cart.addToCart(newData);

        if (res.statusCode === 200) {
            if(!user.uid){
                res.content.uid && user.setUid(res.content.uid);
            }
            user.setBasketChange(Math.random().toString(16))
        }

        setTimeout(() => {
            setVisible(false)
        }, 3000);
    }

  return (
    <form className="actions" onSubmit={handleSubmit(addToBasket)}>
        {card?.traits?.map((it, index) =>
            <div className="form-group product__option" key={index}>
                <p>{it.title}</p>
                <div className="input-radio-label">
                    <div className="input-radio-label__list">
                        {it.elements?.map((el, index) =>
                            <label key={index}>
                                <input type="radio" {...register(it.title)} value={el.name}/>
                                <span>{el.name}</span>
                            </label>
                        )}
                    </div>
                </div>
            </div>
        )}
        <div className='cart-top'>
            <label className="gfont">{app.localizationsItems?.count}: </label>
            <div className="qty-container">
                <button className="qty-decrease" type="button" onClick={minusCount}></button>
                <input className="input-text qty" type="number" value={count}
                    onChange={e => changeCount(e)}
                />
                <button className="qty-increase" type="button" onClick={plusCount}></button>
            </div>
            
            <button className="btn-cart" title="Add to Cart" data-id="qv_item_8">
                {app.localizationsItems?.to_cart}
            </button>
        </div>
    </form>
  )
})

export default AddToCart