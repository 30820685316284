import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import ServiceServices from '../../services/general/ServiceServices';
import NewsCard2 from '../simple/NewsCard2';
import Pagination from './Pagination';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const ServiceGrid = observer(() => {
    const navigate = useNavigate();
    const {app} = useContext(Context);

    const { 
        items, 
        loading, 
        pageCount, 
        params, 
        handlePageClick, 
        setFilterData,
        handlePriceChange
    } = useFetchItems(ServiceServices.index);

  return (
    <div className="container">
        <div className="sns-latestblog sns-latestblog2">
            <Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
            <div className='latestblog-content'>
                <div className='row'>
                    {
                        items?.items.map((item, index) => 
                            <div className="col-md-4 mrb30" key={index}>
                                <NewsCard2 item={item} onClick={() => {navigate(`/${app.lang}/services/${item.slug}`)}} data={true}/>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    </div>
  )
})

export default ServiceGrid