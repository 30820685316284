import React from 'react'
import CategoryCard from '../simple/CategoryCard'

const SubCategories = ({categories}) => {
  return (
    categories?.length >= 1 &&
    <div className='container'>
        <div className="catalog-grid mb-60">
            {categories.map((card, index) =>
                    <CategoryCard card={card} key={index}/>
            )}
         </div>
    </div>
  )
}

export default SubCategories