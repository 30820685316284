import React, { useContext } from 'react'
import Breadcrumbs from '../../components/complex/Breadcrumbs'
import CheckoutContainer from '../../components/complex/CheckoutContainer'
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const Checkout = observer(() => {
    const {app} = useContext(Context);

  return (
    <div>
        <Breadcrumbs title={app.localizationsItems?.checkout}/>
        <CheckoutContainer/>
    </div>
  )
})

export default Checkout