import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from '../..'
import { observer } from 'mobx-react-lite';

const CategoryBreadcrumbs = observer(({items}) => {
    const {app} = useContext(Context);

  return (
    <div id="sns_breadcrumbs" className="wrap">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div id="sns_titlepage"></div>
                    <div id="sns_pathway" className="clearfix">
                        <div className="pathway-inner">
                            <span className="icon-pointer "></span>
                            <ul className="breadcrumbs">
                                <li className="home">
                                    <NavLink to={`/${app.lang}`}>
                                    <i className="fa fa-home"></i>
                                    <span>{app.localizationsItems?.home}</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={`/${app.lang}/catalog`}>
                                        <span>{app.localizationsItems?.catalog}</span>
                                    </NavLink>
                                </li>
                                <li className="category3 last">
                                    <span>{items.title}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
})

export default CategoryBreadcrumbs