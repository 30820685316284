import React from 'react'

const Advantages = ({item}) => {
  return (
    <div className="container">
        <div className='advantages'>
            <h3>{item.title}</h3>
            <div className='advantages-flex'>
                {item.elements.map((advant, index) => 
                <div className='advantages-wrapper' key={index}>
                    <div className='advantages-block-title'>
                    <span>{advant.counter}</span>{advant.title}
                    </div>
                    <div className='text'>{advant.subtitle}</div>
                </div>
                )}
            </div>
        </div>
    </div>
  )
}

export default Advantages