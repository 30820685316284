import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';

const AdvertisingBanner = ({item}) => {
    const [banner, setBanner] = useState();

    useEffect(() => {
        if(item.elements){
            setBanner(item.elements[0])
        }
    }, [item])

  return (
    banner &&
    <div className='container'>
        <div className="sns_banner">
            <a href={banner.link}>
                <img src={banner.image} alt=""/>
            </a>
            <div className='banner-absolute'>
                <div className="style-title" dangerouslySetInnerHTML={{__html: banner.subtitle_top}}></div>
                <div className="style-text1" dangerouslySetInnerHTML={{__html: banner.title}}></div>
                <div className="style-text2" dangerouslySetInnerHTML={{__html: banner.description}}></div>
                {banner.link && <NavLink to={banner.link} className="style-button">{banner.subtitle}</NavLink>}
            </div>
        </div>
    </div>
  )
}

export default AdvertisingBanner