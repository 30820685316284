import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import ToCartModal from "../modals/ToCartModal";
import { observer } from "mobx-react-lite";
import { Context } from "../..";
import img from "../../images/placeholder.png"
import Utils from "../../services/utils";
import ModalCustom from "../modals/ModalCustom";

const ProductCard = observer(({item, ...props}) => {
    const {app} = useContext(Context);

    const [toCartModal, setToCartModal] = useState(false);
    const [open, setOpen] = useState();

    const handleClose = () => setOpen(false);

  return (
    <div className="item-inner" {...props}>
        <div className="prd">
            <div className="item-img clearfix">
                {item.state &&
                    <div className="ico-label">
                        <span className="ico-product ico-new" style={{backgroundColor: `${item.state.subtitle}`}}>{item.state.name}</span>
                    </div>
                }
                <div className="product-image have-additional">
                    {item.detail_image ?
                        <NavLink to={`/${app.lang}/products/${item.slug}`} className="img-main">
                            <img className="fisrt-img" src={item.preview_image ? item.preview_image : img} alt="" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
                            <img className="second-img" src={item.detail_image ? item.detail_image : img} alt="" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
                        </NavLink>
                    :
                        <NavLink to={`/${app.lang}/products/${item.slug}`} className="img-main">
                            <img src={item.preview_image ? item.preview_image : img} alt="" 
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
                        </NavLink>
                    }
                    <div className="action-bot">
                        <div className="wrap-addtocart">
                            {app.cartEnabled ? 
                                <button className="btn-cart" onClick={() => setToCartModal(true)}>
                                    <i className="fa fa-shopping-cart"></i>
                                    <span>{app.localizationsItems?.to_cart}</span>
                                </button>
                            :
                                <button className="btn-cart" onClick={() => setOpen(true)}>
                                    <i className="fa fa-shopping-cart"></i>
                                    <span>{app.localizationsItems?.to_cart}</span>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="item-info">
                <div className="info-inner">
                    <div className="item-title">
                        <NavLink to={`/${app.lang}/products/${item.slug}`}>
                            {item.title}
                        </NavLink>
                    </div>
                    <div className="item-price">
                        <div className="price-box">
                            <span className="regular-price">
                                <span className="price">
                                    {(item.price && item.price > 0) ?
                                            <>
                                                <span className="price1">{Utils.numberWithSpaces(item.price)} {app.localizationsItems?.currency}</span>
                                                {item.old_price && <span className="price2">{Utils.numberWithSpaces(item.old_price)}</span>}
                                            </>
                                            :
                                            app.localizationsItems?.not_specified
                                        }
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {toCartModal && <ToCartModal show={toCartModal} setShow={setToCartModal} item={item}/>}
        {open && <ModalCustom show={open} handleClose={handleClose} productName={item.title}/>}
    </div>
  );
});

export default ProductCard;
