import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import Header from './components/complex/Header';
import Footer from './components/complex/Footer';
import { Context } from '.';
import AppServices from './services/general/AppServices';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './navigation/AppRouter';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./App.scss";
import ScrollToTop from './hooks/scrollToTop';

const App = observer(() => {
    const {app, user} = useContext(Context);

    const fetchdata = async () => {
        const res = await AppServices.settings();
    
        if(res.statusCode === 200){
            let settings = res.content;
            app.setSettings(settings);
            let userEnabled = settings.find(it => it.type === "user-enabled");
            userEnabled && app.setUserCabinet(userEnabled.value);

            let cartEnabled = settings.find(it => it.type === "cart-enabled");
            cartEnabled && app.setCartEnabled(cartEnabled.value);

            let color = settings.find(it => it.type === "color-primary")?.value;
            let accentColor = settings.find(it => it.type === "accent-color")?.value;
            let bodyColor = settings.find(it => it.type === "body-сolor")?.value;
            let accentColorHover = settings.find(it => it.type === "accent-color-hover")?.value;
            let bannerFontColor = settings.find(it => it.type === "banner-font-color")?.value;
            let iconColor = settings.find(it => it.type === "icon-font-color")?.value;
            let whiteColor = settings.find(it => it.type === "white-color")?.value;
            let bannerColor = settings.find(it => it.type === "banner-color")?.value;
            let footerColor = settings.find(it => it.type === "footer-color")?.value;
            let headerColor = settings.find(it => it.type === "header-color")?.value;
            let footerLinkColor = settings.find(it => it.type === "footer-link-color")?.value;
            let footerFontColor = settings.find(it => it.type === "footer-font-color")?.value;

            let head = settings.find(it => it.type === "head")?.value;
            let bottom = settings.find(it => it.type === "bottom")?.value;
            let header = settings.find(it => it.type === "header")?.value;

            color && document.documentElement.style.setProperty('--mainColor', color);
            accentColor && document.documentElement.style.setProperty('--secondaryColor', accentColor);
            bodyColor && document.documentElement.style.setProperty('--bodyColor', bodyColor);
            accentColorHover && document.documentElement.style.setProperty('--accentColorHover', accentColorHover);
            bannerFontColor && document.documentElement.style.setProperty('--bannerFontColor', bannerFontColor);
            iconColor && document.documentElement.style.setProperty('--iconColor', iconColor);
            whiteColor && document.documentElement.style.setProperty('--whiteColor', whiteColor);
            bannerColor && document.documentElement.style.setProperty('--bannerColor', bannerColor);
            footerColor && document.documentElement.style.setProperty('--footerColor', footerColor);
            headerColor && document.documentElement.style.setProperty('--headerColor', headerColor);
            footerLinkColor && document.documentElement.style.setProperty('--footerLinkColor', footerLinkColor);
            footerFontColor && document.documentElement.style.setProperty('--footerFontColor', footerFontColor);
            
            if(head){
                head.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.head.append(divFragment);
                });
            }

            if(header){
                header.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.append(divFragment);
                });
            }

            if(bottom){
                bottom.forEach(element => {
                    const divFragment =  document.createRange().createContextualFragment(element)
                    document.body.prepend(divFragment);
                });
            }
        }

        const res2 = await AppServices.localizations();

        if(res2.statusCode === 200){
            let temp;
            if(res2.content.find(it => it.slug === localStorage.getItem('i18nextLng'))){
                temp = localStorage.getItem('i18nextLng');
            }else{
                temp = res2.content[0].slug
                localStorage.setItem('i18nextLng', temp)
            }
            app.setLang(temp)
            app.setLocalizationsItems(res2.content.find(it => it.slug === temp).description)
            app.setLanguages(res2.content)
        }
    }

    useEffect(() => {
        fetchdata();
    }, [])

  return (
    <BrowserRouter>
        <ScrollToTop />
        <Header/>
        <AppRouter/>
        <Footer/>
    </BrowserRouter>
  )
})

export default App