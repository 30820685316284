import React, { useContext, useEffect, useState } from 'react'
import CatalogServices from '../../services/general/CatalogServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import Utils from '../../services/utils';
import ProductGridCard from '../simple/ProductGridCard';

const MainProducts = observer(({item}) => {
    const {app, user} = useContext(Context);

    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [categories, setCategories] = useState();
    const [items, setItems] = useState();
    let query = new URLSearchParams(useLocation().search);

    const fetchProducts = async () => {
        setLoading(true);
        let str = user.wishlistUid ? `favorite_uid=${user.wishlistUid}&` : '';
        let str2 = query.get('categories[]') ? query.toString() : '';
        let str3 = item.products_type ? `type=${item.products_type}&` : '';

        const res = await CatalogServices.products.index(`${str3}${str}${str2}`);

        if(res.statusCode === 200){
            res.content.items && setItems(res.content.items);
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchProducts()
    }, [query.get('categories[]')])

    const fetachcategories = async () => {
        const res = item.ids ? await CatalogServices.categories.indexIds(item.ids) : await CatalogServices.categories.index();

        if((res.statusCode === 200) && res.content){
            res.content.items && setCategories(res.content.items)
        }
        
    }

    useEffect(() => {
        fetachcategories();
    }, [item])

    const setFilterData = (filterString) => {
        let newParams = new URLSearchParams(filterString);
        if (query.get('order')) {
            newParams.set('order', query.get('order'))
        }
    };

    const changeCategory = (name) => {
        let str = `?categories[]=${name}`;
        setFilterData(str);
        navigate({
            search: `?categories[]=${name}`
        });
    }

    const resetCategories = () => {
        setFilterData('');
        navigate({
            search: ``
        });
    }   

  return (
    <div className={loading ? "container isLoading" : "container"}>
        <div id="sns_content">
            {item?.title && <div className="block-title products-title">
                <h3>{item.title}</h3>
            </div>}
            {items && <div className="sns_mainmidle">
                <div id="sns_producttaps1" className="sns_producttaps_wraps">
                    <ul className="nav nav-tabs" role="tablist">
                        {categories?.length >=1 &&
                            categories.slice(0,5).map((card, index) =>
                                <li role="presentation" className={query.get('categories[]') === card.slug ? "active" : ""} key={index} onClick={() => changeCategory(card.slug)}>
                                    <div className='link'>{Utils.trimText(card.title, 20, '...')}</div>
                                </li>
                        )}
                    </ul>
                    <div className="products-grid row style_grid">
                        {items.slice(0, 10)?.map((card, index) =>
                            <ProductGridCard card={card} key={index}/>
                        )}
                    </div>
                    <h3 className="bt-more" onClick={() => navigate(`/${app.lang}/catalog/main`)}>
                        <span>{app.localizationsItems?.all_products}</span>
                    </h3>
                </div>
            </div>}
        </div>
    </div>
  )
})

export default MainProducts