import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import Article from '../../components/complex/Article';
import Breadcrumbs from '../../components/complex/Breadcrumbs';
import ServiceServices from '../../services/general/ServiceServices';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';

const ServiceDetail = observer(() => {
    const {id} = useParams();
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2) {
                navigate(`/${app.lang}/services/${id}`)  
            }
        }
    }, [app.lang])

    const {info, loading} = useFetchInfo(ServiceServices.view, id);

  return (
    <>
        <Helmet>
            <title>{info?.title}</title>
        </Helmet>
        <Breadcrumbs title={info?.title}/>
        <div className="blog-pagev1 detail cms-simen-home-page-v2 default cmspage">
            {info ? 
              <Article item={info}/> 
              :
              <div className="isLoading"></div>
            }
        </div>
    </>
  )
})

export default ServiceDetail