import React, { useContext, useEffect, useState } from 'react';
import RelatedProducts from './RelatedProducts';
import Slider from './Slider';
import UpsellProducts from './UpsellProducts';
import AddToCart from '../simple/AddToCart';
import TabProductPane from './TabProductPane';
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import ModalCustom from '../modals/ModalCustom';
import Utils from '../../services/utils';
import img from "../../images/placeholder.png"

const ProductOptions = observer(({items}) => {
    const [show, setShow] = useState(false);
    const [image, setImage] = useState();
    const [gallery, setGallery] = useState();
    const [features, setFeatures] = useState();
    const [tabs, settabs] = useState();

    const {app} = useContext(Context);

    useEffect(() => {
        if(items){
            setFeatures(items.features)
            setGallery(items.gallery)
            setImage(items.image)
            settabs(items.elements?.find(it => it?.type === "tab-component")?.elements)
        }
    }, [items])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  return (
    <div id="sns_content" className="wrap layout-m">
        <div className="container">
            <div className="row">
                <div id="sns_main" className="col-md-12 col-main">
                    <div id="sns_mainmidle">
                        <div className="product-view sns-product-detail">
                            <div className="product-essential clearfix">
                                <div className="row row-img">
                                    {gallery ? 
                                        <Slider gallery={gallery}/>
                                        :
                                        <div className="product-img-box col-md-4 col-sm-5 img-pad">
                                            <img src={image ? image : img} alt="" 
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; 
                                                    currentTarget.src=img
                                                }}
                                            />
                                        </div>
                                    }
                                    <div
                                        id="product_shop"
                                        className="product-shop col-md-8 col-sm-7"
                                    >
                                        <div className="item-inner product_list_style">
                                            <div className="item-info">
                                                <div className="item-title">
                                                    {items?.title}
                                                </div>
                                                <div className="item-price">
                                                    <div className="price-box">
                                                    <span className="regular-price">
                                                        {items?.price ?
                                                            <span className="price">{Utils.numberWithSpaces(items?.price)} {app.localizationsItems?.currency}</span>
                                                            :<></>
                                                        }
                                                        {items?.old_price && <span className="price2">{Utils.numberWithSpaces(items?.old_price)} {app.localizationsItems?.currency}</span>}
                                                    </span>
                                                    </div>
                                                </div>    
                                                <div className='item-content' dangerouslySetInnerHTML={{__html: items.subtitle}}></div>                                                    
                                                <div className="product-item">
                                                    <div className='row'>
                                                    {features?.map((item, index) => 
                                                        <div className='col-md-6' key={index}>
                                                        <h5>{item.title}</h5>
                                                        <p>
                                                            {item.value}
                                                        </p>
                                                    </div>
                                                    )}
                                                    </div>
                                                </div>
                                                
                                                {app.cartEnabled ? 
                                                    <AddToCart card={items}/>
                                                    :
                                                    <div className="desc std">
                                                        <button className='black' onClick={() => handleShow()}>{app.localizationsItems?.product_application}</button>
                                                    </div>    
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom row">
                <div className="2coloum-left">
                    <div id="sns_mainm" className="col-md-12">
                        <div id="sns_description" className="description">
                            {tabs ?
                                <div className="sns_producttaps_wraps1">
                                    <TabProductPane item={tabs}/>
                                </div>
                                :
                                <div className="sns_producttaps_wraps1">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li role="presentation" className="active style-detail">
                                        <div>
                                            {app.localizationsItems?.about_product}
                                        </div>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div role="tabpanel" className="tab-pane active" id="home">
                                            <div className="style1">
                                                {items && <p dangerouslySetInnerHTML={{ __html: items.description }}></p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {/*<UpsellProducts/>
                        <RelatedProducts/>*/}
                    </div>
                </div>
            </div>
        </div>
        {show && <ModalCustom show={show} handleClose={handleClose} productName={items.title}/>}
    </div>
  )
})

export default ProductOptions