import React, { useContext, useEffect } from 'react'
import useFetchItems from '../../hooks/useFetchItems'
import AppServices from '../../services/general/AppServices'
import BannerCategory from '../../components/complex/BannerCategory'
import CatalogCategories from '../../components/complex/CatalogCategories'
import Advantages from '../../components/complex/Advantages'
import NewsGrid from '../../components/complex/NewsGrid'
import Partners from '../../components/complex/Partners'
import NewsGrid2 from '../../components/complex/NewsGrid2'
import PointsBlock from '../../components/complex/PointsBlock'
import Description from '../../components/simple/Description'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import { useNavigate, useParams } from 'react-router-dom'

const Catalog = observer(() => {
    const {app} = useContext(Context);
    const navigate = useNavigate();
    const {lang: lang2} = useParams();

    useEffect(() => {
        if(app.lang){
            if(app.lang !== lang2) {
                navigate(`/${app.lang}/catalog`)
            }
        }
    }, [app.lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'catalog')

  return (
    <>
        {loading ?
            <div className="isLoading"></div>
        :
            <div>
                <BannerCategory item={items}/>
                <CatalogCategories/>
                {items.elements?.map((elem, index) => 
                    <div key={index}>
                        {
                            elem.type === "advantages" ?
                                <Advantages item={elem}/>
                            :
                            elem.type === "projects" ?
                                <NewsGrid item={elem}/>
                            :
                            elem.type === "partners" ?
                                <Partners item={elem}/>
                            :
                            elem.type === "articles" ?
                                <NewsGrid2 item={elem}/>
                            :
                            elem.type === "steps" ?
                                <PointsBlock item={elem}/>
                            :
                            elem.type === "text-information" ?
                                <Description item = {elem}/>
                            :
                            /*elem.type === "products" ?
                                <CategoryGrid item={items}/>
                            :*/
                        <></>
                        }
                    </div>
                )}
            </div>
        }
    </>
  )
})

export default Catalog