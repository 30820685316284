import http from "./http-common"
import {getData} from "../handlers";

export default class DictionariesServices {
    static orderDeliveries = () => {
        return getData(http, `/app/dictionaries/orderDeliveries`);
    }

    static orderPayments = () => {
        return getData(http, `/app/dictionaries/orderPayments`);
    }

}