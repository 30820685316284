import React, { useContext, useState } from 'react'
import Utils from '../../services/utils'
import { observer } from 'mobx-react-lite'
import { Context } from '../..'
import { NavLink, useNavigate } from 'react-router-dom'
import img from "../../images/placeholder.png"
import ToCartModal from '../modals/ToCartModal'
import ModalCustom from '../modals/ModalCustom'

const ProductGridCard = observer(({card}) => {
    const {app} = useContext(Context);

    const [toCartModal, setToCartModal] = useState(false);
    const [open, setOpen] = useState();

    const handleClose = () => setOpen(false);
    
  return (
    <div className="item col-lg-2d4 col-md-3 col-sm-4 col-xs-6 col-phone-12">
        <div className="item-inner">
            <div className="prd">
                <div className="item-img clearfix">
                    {card.state &&
                        <div className="ico-label">
                            <span className="ico-product ico-new" style={{backgroundColor: `${card.state.subtitle}`}}>{card.state.name}</span>
                        </div>
                    }
                    <NavLink className="product-image have-additional" to={`/${app.lang}/products/${card.slug}`}>
                        <span className="img-main">
                            <img src={card.image ? card.image : img} alt=""
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; 
                                    currentTarget.src = img
                                }}
                            />
                        </span>
                    </NavLink>
                    <div className="action-bot">
                        <div className="wrap-addtocart">
                            {app.cartEnabled ? 
                                <button className="btn-cart" onClick={() => setToCartModal(true)}>
                                    <i className="fa fa-shopping-cart"></i>
                                    <span>{app.localizationsItems?.to_cart}</span>
                                </button>
                            :
                                <button className="btn-cart" onClick={() => setOpen(true)}>
                                    <i className="fa fa-shopping-cart"></i>
                                    <span>{app.localizationsItems?.to_cart}</span>
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="item-info">
                    <div className="info-inner">
                        <div className="item-title">
                            <NavLink to={`/${app.lang}/products/${card.slug}`}>{card.title} </NavLink>...
                        </div>
                        <div className="item-price">
                            <div className="price-box">
                                <span className="regular-price">
                                    <span className="price">
                                        {(card.price && card.price > 0) ?
                                            <>
                                                <span className="price1">{Utils.numberWithSpaces(card.price)} {app.localizationsItems?.currency}</span>
                                                {card.old_price && <span className="price2">{Utils.numberWithSpaces(card.old_price)}</span>}
                                            </>
                                            :
                                            app.localizationsItems?.not_specified
                                        }
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {toCartModal && <ToCartModal show={toCartModal} setShow={setToCartModal} item={card}/>}
        {open && <ModalCustom show={open} handleClose={handleClose} productName={card.title}/>}
    </div>
  )
})

export default ProductGridCard