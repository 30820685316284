import React from "react";
import HeaderLogo from "../simple/HeaderLogo";
import HeaderTop from "../simple/HeaderTop";
import Menu from "./Menu";

const Header = () => {
  return (
    <div id="sns_header" className="wrap">
      <HeaderTop/>
      <HeaderLogo/>
      <Menu/>
    </div>
  );
};

export default Header;
