import React, { useState } from 'react'
import MobMenuHeader from '../simple/MobMenuHeader';

const MobMenu = ({items}) => {
    const [active, setActive] = useState();
  return (
    <div id="sns_mommenu" className="menu-offcanvas hidden-md hidden-lg" >
        <span className="btn2 btn-navbar leftsidebar">
            <i className="fa fa-align-left"></i>
            <span className="overlay"></span>
        </span>
        <span className="btn2 btn-navbar" onClick={() => setActive(true)}>
            <i className="fa fa-align-justify"></i>
            <span className="overlay"></span>
        </span>
        <span className="btn2 btn-navbar rightsidebar">
            <i className="fa fa-align-right"></i>
            <span className="overlay"></span>
        </span>

        <div id="menu_offcanvas" className={active ? "offcanvas active" : "offcanvas" }>
            <span className='mob-menu-icon' onClick={() => setActive(false)}>
                <i className='fa fa-close'></i>
            </span>
            <ul className="mainnav">
                {items?.map((item, index) => 
                    <MobMenuHeader item={item} key={index} active={active} setActive={setActive}/>
                )}
            </ul>
        </div>
        {active && <div className='close-menu-mob' onClick={() => setActive(false)}></div>}
    </div>
  )
}

export default MobMenu